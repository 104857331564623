import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faLinkedinIn,
  faFacebookF,
  faInstagram,
} from '@fortawesome/free-brands-svg-icons'
import PropTypes from 'prop-types'
import { Link as LinkWithLocale } from '../i18n'
import logo from '../img/logo.svg'

const year = new Date().getFullYear()

const Footer = () => (
  <footer className="footer has-background-primary">
    <div className="container">
      <div className="columns">
        <div className="logo is-2 column has-left-content">
          <div className="has-text-left" style={{ marginRight: 'auto' }}>
            <LinkWithLocale to="/" className="navbar-item">
              <figure className="image">
                <img src={logo} alt="Kristiania Management" className="logo" />
              </figure>
            </LinkWithLocale>
          </div>
        </div>
        <div id="copyrights" className="column is-8 has-centered-content">
          <p>
            {year} - Kristiania Management
            <span className="is-hidden-touch"> - Org nr. 820 854 322</span>
          </p>
        </div>
        <div className="column has-centered-content so-me">
          <ul>
            <IconItem
              href="https://www.linkedin.com/company/kristiania-management/"
              icon={faLinkedinIn}
            />
            <IconItem
              href="https://www.facebook.com/Kristianiamanagement"
              icon={faFacebookF}
            />
            <IconItem
              href="https://www.facebook.com/Kristianiamanagement"
              icon={faInstagram}
            />
          </ul>
        </div>
      </div>
    </div>
  </footer>
)

const IconItem = ({ href, icon }) => (
  <li>
    <a className="icon" href={href} rel="noopener noreferrer">
      <span className="icon is-small">
        <FontAwesomeIcon icon={icon} />
      </span>
    </a>
  </li>
)

IconItem.propTypes = {
  href: PropTypes.string,
  icon: PropTypes.object,
}

export default Footer

import React from 'react'
import Helmet from 'react-helmet'
import PropTypes from 'prop-types'

import Footer from '../components/Footer'
import NavbarNO from '../components/Navbar-NO'
import NavbarEN from '../components/Navbar-EN'
import CookieConsent from 'react-cookie-consent'
import { injectIntl, FormattedMessage } from 'react-intl'
import favicon from '../img/favicon.png'
import './all.sass'

const Layout = ({ children, location, intl, seoTitle, seoDescription }) => {
  let Navbar = <div />
  switch (intl.locale) {
    case 'en':
      Navbar = <NavbarEN currentPath={location} />
      break
    case 'no':
      Navbar = <NavbarNO currentPath={location} />
      break
    default:
      Navbar = <NavbarNO currentPath={location} />
  }
  const defaultDescription =
    'Kristiania Management hjelper kunder med å rekruttere de riktige kandidatene. Med vår lange erfaring innen bemanning og rekruttering, kjenner vi oss trygge på at vi kan love våre kunder en treffsikker og smidig rekrutteringsprosess.'
  return (
    <div>
      <Helmet
        link={[{ rel: 'shortcut icon', type: 'image/png', href: `${favicon}` }]}
      >
        <meta charSet="utf-8" />
        <title>{seoTitle ? seoTitle : 'Kristiania Management'}</title>
        <meta
          name="google-site-verification"
          content="L_ySPKDPvXpE3DWEU2tTdvwE9_NK1WHtBUrIu7weMw8"
        />

        <meta
          name="description"
          content={seoDescription ? seoDescription : defaultDescription}
        />
        <meta property="og:url" content="https://kristianiamanagement.no" />
        <meta
          property="og:title"
          content={seoTitle ? seoTitle : 'Kristiania Management'}
        />
        <meta
          property="og:description"
          content={seoDescription ? seoDescription : defaultDescription}
        />
        <meta
          name="google-site-verification"
          content="Am-D0bWrr_2xUTojL3YPukAsnztPdOXEyo98rLt0wYA"
        />
        <html lang="nb" />
        {/*<meta property="og:image" content={image} /> */}
        {/* <meta property="fb:app_id" content={seo.social.fbAppID} /> */}
      </Helmet>
      {Navbar}
      {children}
      <Footer />
      <CookieConsent
        location="bottom"
        buttonText="Den er grei!"
        cookieName="cookie"
        style={{ background: '#EDEDED', color: '#000', padding: '15px' }}
        buttonClasses="button is-primary is-large"
        buttonStyle={{
          background: '#192230',
          fontSize: '24px',
          color: 'white',
          padding: '20px 10px',
          margin: '15px',
        }}
        expires={150}
      >
        <FormattedMessage id="cookieConsent" />
      </CookieConsent>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.element,
  location: PropTypes.string,
  intl: PropTypes.object,
}

export default injectIntl(Layout)

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ReactFlagsSelect from 'react-flags-select'
import 'react-flags-select/scss/react-flags-select.scss'
import fetch from 'cross-fetch'

/* eslint-disable */
class Language extends Component {
  static contextTypes = {
    language: PropTypes.object,
  }

  async handleChange(countryCode) {
    const { language } = this.context
    const { originalPath } = language
    countryCode = this.reverseTranslateValues(countryCode)
    this.setState({ countryCode }, async () => {
      localStorage.setItem('language', countryCode)
      const path = `/${countryCode}${originalPath.slice(3)}`
      const res = await fetch(path)
      if (res.status >= 400) {
        window.location.href = `/${countryCode}`
        return
      }
      window.location.href = path
    })
  }

  translateLocaleValues(value) {
    if (!value) return undefined
    const valueUpperCase = value.toUpperCase()
    if (valueUpperCase === 'EN') return 'GB'
    return valueUpperCase
  }

  reverseTranslateValues(countryCode) {
    if (!countryCode) return undefined
    const valueLowerCase = countryCode.toLowerCase()
    if (valueLowerCase === 'gb') return 'en'
    return valueLowerCase
  }

  render() {
    const { language } = this.context
    const { languages } = language
    const { locale } = language
    const localeCode = this.translateLocaleValues(locale)

    if (!languages) return ''

    let customLabels = {}
    languages.forEach(language => {
      let key = this.translateLocaleValues(language.value)
      customLabels[key] = language.text
    })
    let countries = languages.map(language => {
      return this.translateLocaleValues(language.value)
    })

    return (
      <ReactFlagsSelect
        countries={countries}
        customLabels={customLabels}
        className="menu-flags"
        placeholder="Velg språk"
        defaultCountry={localeCode.toUpperCase()}
        showSelectedLabel={false}
        showOptionLabel={false}
        onSelect={event => this.handleChange(event)}
      />
    )
  }
}

export default Language

import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import PropTypes from 'prop-types'
import Navbar from './Navbar'

const DynamicNavbar = ({ props, currentPath }) => {
  return (
    <StaticQuery
      query={graphql`
        query NavbarNOQuery {
          allMarkdownRemark(
            filter: { fileAbsolutePath: { regex: "/no/innstillinger/navbar/" } }
          ) {
            edges {
              node {
                frontmatter {
                  menuLinks {
                    name
                    link
                    dropdown {
                      name
                      link
                    }
                  }
                }
              }
            }
          }
        }
      `}
      render={data => (
        <Navbar data={data} currentPath={currentPath} {...props} />
      )}
    />
  )
}

DynamicNavbar.propTypes = {
  props: PropTypes.object,
  currentPath: PropTypes.string,
  intl: PropTypes.object,
}

export default DynamicNavbar

import React from 'react'
import logo from '../img/logo.svg'
import PropTypes from 'prop-types'
import { Link as LinkWithLocale } from '../i18n'
import LanguageSelect from './LanguageSelect'
// import { FormattedMessage } from 'react-intl';
import { injectIntl } from 'react-intl'

class Navbar extends React.Component {
  constructor(props) {
    super(props)
  }
  state = {
    isActive: false,
  }

  toggleNav = () => {
    this.setState(prevState => ({
      isActive: !prevState.isActive,
    }))
  }
  render() {
    const menuLinks = this.props.data.allMarkdownRemark.edges[0].node
      .frontmatter.menuLinks
    const { intl } = this.props
    return (
      <nav className="navbar is-primary">
        <div className="container">
          <div className="navbar-brand">
            <LinkWithLocale to="/" className="navbar-item">
              <figure className="image">
                <img src={logo} alt="Kristiania Management" className="logo" />
              </figure>
            </LinkWithLocale>
            <a
              role="button"
              aria-label="menu"
              aria-expanded="false"
              className={
                this.state.isActive
                  ? 'navbar-burger burger nav-toggle is-active'
                  : 'navbar-burger burger nav-toggle'
              }
              data-target="navbarMenuHeroC"
              onClick={this.toggleNav}
            >
              <span aria-hidden="true" />
              <span aria-hidden="true" />
              <span aria-hidden="true" />
            </a>
          </div>
          <div
            id="navbarMenuHeroC"
            className={
              this.state.isActive ? 'navbar-menu is-active' : 'navbar-menu'
            }
          >
            <NavbarItems menuLinks={menuLinks} intl={intl} />
          </div>
        </div>
      </nav>
    )
  }
}

const NavbarItems = ({ menuLinks }) => (
  <div className="navbar-end">
    {menuLinks.map(menuItem => (
      <NavbarItem key={menuItem.name} menuItem={menuItem} />
    ))}
    <div className="navbar-item is-tab">
      <LanguageSelect />
    </div>
  </div>
)

const NavbarItem = ({ menuItem }) => {
  if (menuItem.dropdown) {
    return <MenuDropDown menuItem={menuItem} />
  } else {
    return (
      <LinkWithLocale className="navbar-item" to={menuItem.link}>
        {menuItem.name}
      </LinkWithLocale>
    )
  }
}

const MenuDropDown = ({ menuItem }) => (
  <div className="navbar-item has-dropdown is-hoverable">
    <a href="#" className="navbar-link">
      {menuItem.name}
    </a>
    <div className="navbar-dropdown">
      {menuItem.dropdown.map(subitem => (
        <LinkWithLocale
          key={subitem.link}
          className="navbar-item"
          to={subitem.link}
        >
          {subitem.name}
        </LinkWithLocale>
      ))}
    </div>
  </div>
)

Navbar.propTypes = {
  data: PropTypes.object,
  intl: PropTypes.object,
}

NavbarItems.propTypes = {
  menuLinks: PropTypes.array,
  intl: PropTypes.object,
}

NavbarItem.propTypes = {
  menuItem: PropTypes.object,
}
MenuDropDown.propTypes = {
  menuItem: PropTypes.object,
}

export default injectIntl(Navbar)

/* eslint-disable */
module.exports = {
  categories: 'Kategorier',
  cookieConsent:
    'Vi bruker informasjonskapsler for å analysere trafikken vår. Kristiania Management er opptatt av personvern og at du ikke trenger å være redd for at personlig informasjon kommer på avveie når du besøker vår side.',
  mainPages: 'Hovedsider',
  email: 'E-post',
  phone: 'Telefon',
  address: 'Adresse',
  contactInfo: 'Kontakt info',
  madeBy: 'Utviklet av ',
  ok: 'OK',
  namePlaceholder: 'Skriv inn ditt navn her',
  emailPlaceholder: 'Skriv inn din e-postadresse her.',
  phonePlaceholder: 'Skriv inn ditt telefonnummer her',
  messagePlaceholder: 'Hva trenger du hjelp med?',
  companyPlaceholder: 'Hvilken organisasjon hører du til?',
  buttonSend: 'Send',
  readMore: 'Les mer',
  questionsContactUs: 'Spørsmål? Ta kontakt',
  fillInBlanks: 'Fyll inn feltene og send oss en mail',
  simplePage: 'Side',
  homePage: 'Hjem',
  aboutPage: 'Om oss',
  contactPage: 'Kontakt oss',
  showAll: 'Vis alle',
  contactUsForAConversation: 'Kontakt oss for en uforpliktende samtale',
  contactUs: 'Kontakt oss',
  registerCV: 'Registrer CV',
  chooseService: 'Velg tjeneste',
  recruitment: 'Rekruttering',
  staffing: 'Bemanning',
  search: 'Search',
  consultancyServices: 'Konsulenttjenester',
  partnerManagement: 'Partner Management',
  positions: 'Stillinger',
  mapOverKM:
    'Kart over hvor Kristiania Management holder til - gjennom Google Maps',
}

/* eslint-disable */
module.exports = {
  categories: 'Categories',
  cookieConsent:
    'We use cookies to analyze our traffic. Kristiania Management is concerned with privacy and that you do not need to be afraid for personal information to go astray when you visit our site.',
  mainPages: 'Main pages',
  email: 'Email',
  phone: 'Phone',
  address: 'Address',
  contactInfo: 'Contact info',
  products: 'Products',
  madeBy: 'Made by ',
  ok: 'OK',
  namePlaceholder: 'Please write your full name here',
  emailPlaceholder: 'Please write your email here',
  phonePlaceholder: 'Please write your phone number here',
  messagePlaceholder: 'Please write what we can help you with here',
  companyPlaceholder: 'Please state your organization name here',
  buttonSend: 'Send',
  readMore: 'Read more',
  questionsContactUs: 'Questions? Contact us',
  fillInBlanks: 'Fill in the fields and send us an email',
  simplePage: 'Page',
  homePage: 'Home',
  aboutPage: 'About',
  contactPage: 'Contact',
  showAll: 'View all',
  contactUsForAConversation: 'Contact us for an informal talk',
  contactUs: 'Contact us',
  registerCV: 'Register CV',
  chooseService: 'Choose service',
  recruitment: 'Recruiting',
  staffing: 'Staffing',
  search: 'Search',
  positions: 'Positions',
  consultancyServices: 'Consultancy Services',
  partnerManagement: 'Partner Management',
  mapOverKM: 'Map over where Kristiania Management has their offices',
}

/* eslint-disable */

const localeData = [
  ...require('react-intl/locale-data/en'),
  ...require('react-intl/locale-data/no'),
]

module.exports = {
  localeData,
  languages: [{ value: 'no', text: 'Norsk' }, { value: 'en', text: 'English' }],
}
